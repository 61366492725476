// import React from 'react'
// import "./Social2.css";
// import img from "../../assets/s2-1.png"
// import img1 from "../../assets/s2-2.png"
// import img2 from "../../assets/s2-3.png"
// import img3 from "../../assets/s2-4.png"
// import img4 from "../../assets/s2-5.png"
// import img5 from "../../assets/s2-6.png"
// import img6 from "../../assets/s2-7.png"
// import img7 from "../../assets/s2-8.png"
// import img8 from "../../assets/s2-9.png"
// import img9 from "../../assets/s2-10.png"

// const Social2 = () => {
//   return (
//     <div className='max-w-5xl mx-auto mt-24 pb-20'>
//       <h1 className='social2-h text-center font-manrope'>Institutional Lending Partner with $2.16+ Bn AUM</h1>
//       <h2 className='social2-h text-center font-manrope'>Accessing 53K + Businesses through our B2B Partners</h2>
//       <div className=" max-w-5xl mx-auto flex justify-center gap-4 py-14">
// <img src={img} alt="" style={{width: "175px", height: "64px"}} />
// <img src={img1} alt="" style={{width: "175px", height: "64px"}} />
// <img src={img2} alt="" style={{width: "139px", height: "64px"}} />
// <img src={img3} alt="" style={{width: "119px", height: "64px"}} />
// <img src={img4} alt="" style={{width: "129px", height: "64px"}} />
// <img src={img5} alt="" style={{width: "120px", height: "64px"}} />
//       </div>
//       <div className=" max-w-5xl mx-auto flex justify-center items-center object-contain gap-4">
// <img src={img6} alt="" style={{width: "129px", height: "64px"}} />
// <img src={img7} alt="" style={{width: "113px", height: "64px"}} />
// <img src={img8} alt="" style={{width: "115px", height: "64px"}} />
// <img src={img9} alt="" style={{width: "125px", height: "64px"}} />
//       </div>
//     </div>
//   )
// }

// export default Social2
import React from "react";
import { motion } from "framer-motion"; // Importing framer-motion
import "./Social2.css";
import img from "../../assets/s2-1.webp";
import img1 from "../../assets/s22.webp";
import img2 from "../../assets/s23.webp";
import img3 from "../../assets/s24.webp";
import img4 from "../../assets/s25.webp";
import img5 from "../../assets/s26.webp";
import img6 from "../../assets/s27.webp";
import img7 from "../../assets/s28.webp";
import img8 from "../../assets/s29.webp";
import img9 from "../../assets/s30.webp";
import img10 from "../../assets/s31.webp";
import img11 from "../../assets/Firoza.png"
import img12 from "../../assets/Flow48.png";
import img13 from "../../assets/flapkap.webp";

const Social2 = () => {
  const topImages = [img, img1, img2, img3, img4, img5, img6, img7, img8, img9, img10, img11, img12, img13];

  // Framer Motion animation variants for staggered animations
  const container = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.2, // Controls the delay between animations for each child
      },
    },
  };

  const item = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0 },
  };

  return (
    <div className="max-w-6xl mx-auto mt-12 sm:mt-16 md:mt-20 lg:mt-24 px-4 sm:px-6 lg:px-8">
      <h1 className="social2-h text-center font-manrope text-2xl sm:text-3xl md:text-4xl lg:text-5xl mb-4">
        Institutional Lending Partner with $2.16+ Bn AUM
      </h1>

      {/* Top Images with animation and hover effect */}
      <motion.div
        className="flex flex-wrap justify-center items-center gap-4 sm:gap-6 md:gap-8 mt-8 mb-8 mx-auto"
        variants={container}
        initial="hidden"
        animate="visible"
      >
        {topImages.map((img, index) => (
          <motion.img
            key={index}
            src={img}
            alt={`Partner ${index + 1}`}
            className="w-20 h-20 sm:w-24 sm:h-24 md:w-28 md:h-28 object-contain"
            variants={item}
            whileHover={{ scale: 1.1 }} // Hover effect for scaling the image
            transition={{ type: "spring", stiffness: 300 }}
          />
        ))}
      </motion.div>




      {/* Bottom Images with animation and hover effect */}
      {/* <motion.div
        className="flex justify-center gap-4 sm:gap-6 md:gap-8"
        // className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-6 gap-4 sm:gap-6 md:gap-8 justify-items-center"

        variants={container}
        initial="hidden"
        animate="visible"
      >
        {bottomImages.map((img, index) => (
          <motion.img
            key={index}
            src={img}
            alt={`Partner ${index + 9}`}
            className="w-20 h-20 sm:w-24 sm:h-24 md:w-28 md:h-28 object-contain"
            variants={item}
            whileHover={{ scale: 1.1 }} // Hover effect for scaling the image
            transition={{ type: "spring", stiffness: 300 }}
          />
        ))}
      </motion.div> */}
    </div>
  );
};

export default Social2;


// import React from "react";
// import { Link } from "react-router-dom";
// import { motion } from "framer-motion";
// import img from "../../assets/footer_main.svg";
// import './Footer.css';
// export default function Footer() {
//   return (
//     <footer className="bg-color-custom text-white py-16 px-6 md:px-12 lg:px-24">
//       <div className="max-w-[100%] mx-auto flex justify-start items-start gap-[12em] custome-style">
//         {/* Logo Section */}
//         <motion.div
//           className="col-span-2 md:col-span-1"
//           initial={{ opacity: 0 }}
//           animate={{ opacity: 1 }}
//           transition={{ duration: 0.7, ease: "easeOut" }}
//         >
//           <img
//             src={img}
//             alt="DeFa Logo"
//             className="h-12 mb-6 md:mb-4 transition-transform transform hover:scale-110 duration-500 ease-in-out"
//           />
//         </motion.div>
//         <div className="flex justify-around w-[100%] custome_tag">
//           {/* Product Sections */}

//           <motion.div
//             className="flex flex-col items-center md:items-start custome-mobile-style"
//             initial={{ opacity: 0, y: 20 }}
//             animate={{ opacity: 1, y: 0 }}
//             transition={{ duration: 0.7, delay: 0.1, ease: "easeOut" }}
//           >
//             <h3 className="font-semibold text-lg mb-4 tracking-wide transition-colors duration-300 hover:text-indigo-200">
//               Product
//             </h3>
//             <ul className="space-y-2">
//               <li>
//                 <Link
//                   to="/defa-dex"
//                   className="hover:text-indigo-300 transition-colors duration-300"
//                 >
//                   DeFa DEX
//                 </Link>
//               </li>
//               <li>
//                 <Link
//                   to="/strategies"
//                   className="hover:text-indigo-300 transition-colors duration-300"
//                 >
//                   Strategies
//                 </Link>
//               </li>
//               <li>
//                 <Link
//                   to="/general-docs"
//                   className="hover:text-indigo-300 transition-colors duration-300"
//                 >
//                   General Docs
//                 </Link>
//               </li>
//             </ul>
//           </motion.div>

//           <motion.div
//             className="flex flex-col items-center md:items-start custome-mobile-style"
//             initial={{ opacity: 0, y: 20 }}
//             animate={{ opacity: 1, y: 0 }}
//             transition={{ duration: 0.7, delay: 0.2, ease: "easeOut" }}
//           >
//             <h3 className="font-semibold text-lg mb-4 tracking-wide transition-colors duration-300 hover:text-indigo-200">
//               Product
//             </h3>
//             <ul className="space-y-2">
//               <li>
//                 <Link
//                   to="/defa-dex"
//                   className="hover:text-indigo-300 transition-colors duration-300"
//                 >
//                   DeFa DEX
//                 </Link>
//               </li>
//               <li>
//                 <Link
//                   to="/strategies"
//                   className="hover:text-indigo-300 transition-colors duration-300"
//                 >
//                   Strategies
//                 </Link>
//               </li>
//               <li>
//                 <Link
//                   to="/general-docs"
//                   className="hover:text-indigo-300 transition-colors duration-300"
//                 >
//                   General Docs
//                 </Link>
//               </li>
//             </ul>
//           </motion.div>

//           <motion.div
//             className="flex flex-col items-center md:items-start custome-mobile-style"
//             initial={{ opacity: 0, y: 20 }}
//             animate={{ opacity: 1, y: 0 }}
//             transition={{ duration: 0.7, delay: 0.3, ease: "easeOut" }}
//           >
//             <h3 className="font-semibold text-lg mb-4 tracking-wide transition-colors duration-300 hover:text-indigo-200">
//               Product
//             </h3>
//             <ul className="space-y-2">
//               <li>
//                 <Link
//                   to="/defa-dex"
//                   className="hover:text-indigo-300 transition-colors duration-300"
//                 >
//                   DeFa DEX
//                 </Link>
//               </li>
//               <li>
//                 <Link
//                   to="/strategies"
//                   className="hover:text-indigo-300 transition-colors duration-300"
//                 >
//                   Strategies
//                 </Link>
//               </li>
//               <li>
//                 <Link
//                   to="/general-docs"
//                   className="hover:text-indigo-300 transition-colors duration-300"
//                 >
//                   General Docs
//                 </Link>
//               </li>
//             </ul>
//           </motion.div>


//           {/* Follow Us Section */}
//           <motion.div
//             className="flex flex-col items-center md:items-start custome-mobile-style"
//             initial={{ opacity: 0, y: 20 }}
//             animate={{ opacity: 1, y: 0 }}
//             transition={{ duration: 0.7, delay: 0.3, ease: "easeOut" }}
//           >
//             <h3 className="font-semibold text-lg mb-4 tracking-wide transition-colors duration-300 hover:text-indigo-200">
//               Follow Us
//             </h3>
//             <ul className="space-y-2">
//               <li>
//                 <Link
//                   to="/linkedin"
//                   className="hover:text-indigo-300 transition-colors duration-300"
//                 >
//                   LinkedIn
//                 </Link>
//               </li>
//               <li>
//                 <Link
//                   to="/twitter"
//                   className="hover:text-indigo-300 transition-colors duration-300"
//                 >
//                   Twitter
//                 </Link>
//               </li>
//               <li>
//                 <Link
//                   to="/facebook"
//                   className="hover:text-indigo-300 transition-colors duration-300"
//                 >
//                   Facebook
//                 </Link>
//               </li>
//               <li>
//                 <Link
//                   to="/instagram"
//                   className="hover:text-indigo-300 transition-colors duration-300"
//                 >
//                   Instagram
//                 </Link>
//               </li>
//             </ul>
//           </motion.div>
//         </div>
//       </div>

//       {/* Footer Bottom Links */}
//       <motion.div
//         className="border-t mt-12 pt-8 flex flex-col md:flex-row justify-between items-center"
//         initial={{ opacity: 0 }}
//         animate={{ opacity: 1 }}
//         transition={{ duration: 0.7, delay: 0.4, ease: "easeOut" }}
//         style={{ borderColor: "white" }} // White separator line
//       >
//         <div className="flex flex-col md:flex-row items-center space-y-4 md:space-y-0 md:space-x-4 text-center md:text-left">
//           <Link
//             to="/privacy-policy"
//             className="hover:text-indigo-300 transition-colors duration-300"
//           >
//             Privacy Policy
//           </Link>
//           <span className="hidden md:block">|</span>
//           <Link
//             to="/terms"
//             className="hover:text-indigo-300 transition-colors duration-300"
//           >
//             Terms & Conditions
//           </Link>
//           <span className="hidden md:block">|</span>
//           <Link
//             to="/cookie-policy"
//             className="hover:text-indigo-300 transition-colors duration-300"
//           >
//             Cookie Policy
//           </Link>
//         </div>
//         <p className="text-sm mt-4 md:mt-0">&copy; DeFa 2024</p>
//       </motion.div>
//     </footer>
//   );
// }

import React from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import img from "../../assets/footer_main.svg";
import './Footer.css';

export default function Footer() {
  return (
    <footer className="bg-color-custom text-white py-16 px-6 md:px-12 lg:px-24">
      <div className="max-w-7xl mx-auto flex flex-col md:flex-row justify-between items-center md:items-start gap-12">
        {/* Logo Section */}
        <motion.div
          className="mb-6 md:mb-0"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.7, ease: "easeOut" }}
        >
          <img
            src={img}
            alt="DeFa Logo"
            className="h-12 transition-transform transform hover:scale-110 duration-500 ease-in-out"
          />
        </motion.div>

        {/* Follow Us Section */}
        <motion.div
          className="flex flex-col items-center md:items-start text-center md:text-left"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.7, delay: 0.3, ease: "easeOut" }}
        >
          <h3 className="font-semibold text-lg mb-4 tracking-wide transition-colors duration-300 hover:text-indigo-200">
            Follow Us
          </h3>
          <ul className="space-y-2">
            <li>
              <Link
                to="https://www.linkedin.com/company/defaprimitive/?viewAsMember=true"
                className="hover:text-indigo-300 transition-colors duration-300"
              >
                LinkedIn
              </Link>
            </li>
            <li>
              <Link
                to="https://x.com/defaprimitive"
                className="hover:text-indigo-300 transition-colors duration-300"
              >
                X
              </Link>
            </li>
            <li>
              <Link
                to="https://t.me/InvoiceMate_Official"
                className="hover:text-indigo-300 transition-colors duration-300"
              >
                Telegram
              </Link>
            </li>
          </ul>
        </motion.div>
      </div>
    </footer>
  );
}


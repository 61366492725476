import { useState, useEffect } from "react";
import { motion } from "framer-motion";
import Navbar from "../../components/Navbar/Navbar"; // Uncomment this when you need the Navbar
import Footer from "../../components/Footer/Footer"; // Uncomment this when you need the Footer
import Social1 from "../../components/Social1/Social1";
import CountUp from "react-countup"; // For counting effect
import "./About.css";
import img from "../../assets/access.png";
import leftImg from "../../assets/Capa_1.png";

const stats = [
  { label: "Invoices Issued", value: "263", suffix: "K+" },
  { label: "Worth Invoices Issued", value: "200", prefix: "$", suffix: "M+" },
  { label: "Businesses in pipeline", value: "53000", suffix: "+" },
  { label: "Bad Debts", value: "0" },
];

const teamMembers = [
  {
    name: "Muhammad Salman",
    role: "Founder & CEO",
    image: "./images/team/Muhammad_Salman.png", // Make sure this is a valid path
    link: "https://ae.linkedin.com/in/chiefmate",
  },
  {
    name: "Muhammad Ibrahim",
    role: "Co-Founder & COO",
    image: "./images/team/Muhammad_Ibrahim.png",
    link: "https://ae.linkedin.com/in/m-ibrahim-salman",
  },
  {
    name: "Juan Sebastian",
    role: "Content Manager",
    image: "./images/team/Juan_Sebastian.png",
    link: "https://www.linkedin.com/in/jsburgos/",
  },
  {
    name: "Laura Gomez",
    role: "Head of Design",
    image: "./images/team/Laura_Gomez.png",
    link: "https://www.linkedin.com/in/lagora/",
  },
  {
    name: "Rafael Schultz",
    role: "Corporate Strategy",
    image: "./images/team/Rafael_Schultz.png",
    link: "https://de.linkedin.com/in/rafaelschultz",
  },
  {
    name: "Saeed Al Hebsi",
    role: "Head of Partnerships",
    image: "./images/team/Saeed_Al_Hebsi.png",
    link: "https://www.linkedin.com/in/saeed-alhebsi/",
  },
  {
    name: "Juan Sebastian",
    role: "Content Manager",
    image: "./images/team/6th_image.png",
    link: "https://www.linkedin.com/in/jsburgos/",
  },
  {
    name: "Rishabh Gupta",
    role: "Product and Strategy",
    image: "./images/team/Rishabh_Gupta.png",
    link: "https://www.linkedin.com/in/web3incubation/",
  },
  {
    name: "Muhammad Zeeshan Abid",
    role: "Finance Head",
    image: "./images/team/Muhammad_Zeeshan_Abid.png",
    link: "https://www.linkedin.com/in/muhammad-zeeshan-abid-fca-93a00919/?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app",
  },
];

export default function About() {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setIsVisible(true);
  }, []);

  return (
    <div className="min-h-screen bg-about-us text-white">
      <Navbar />
      <main className="container mx-auto px-4 py-8 sm:py-12 mt-32 md:mt-16 md:py-16 lg:py-24 mt-[5rem]">
        {/* About Section with Parallax Animation */}
        <motion.section
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: isVisible ? 1 : 0, y: isVisible ? 0 : 50 }}
          transition={{ duration: 0.6, ease: "easeOut" }}
          className="mb-12 sm:mb-16 md:mb-20 text-center"
        >
          <h1 className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl font-bold mb-4 md:mb-6 lg:mb-8">
            About the Company
          </h1>
          <p className="max-w-md sm:max-w-lg md:max-w-2xl mx-auto text-sm sm:text-base md:text-lg lg:text-xl px-2">
            DeFa is the Decentralized Factoring Protocol.
          </p>
          <p className="max-w-md sm:max-w-lg md:max-w-2xl mx-auto text-sm sm:text-base md:text-lg lg:text-xl px-2 mt-4">
            InvoiceMate is introducing the new DeFi primitive that offers stable
            and secure yields backed by real world revenue, Connecting Real
            World Businesses with DeFi Investors.
          </p>
        </motion.section>

        {/* Stats Section with Interactive Hover and Parallax */}

        <motion.section
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: isVisible ? 1 : 0, y: isVisible ? 0 : 50 }}
          transition={{ duration: 0.6, delay: 0.2, ease: "easeOut" }}
          className="text-center max-w-7xl mx-auto py-12 lg:py-24 px-6 lg:flex lg:items-center lg:justify-between"
        >
          {/* Left Image Section */}
          <div className="lg:block lg:w-1/2 flex justify-center">
            <img
              src={leftImg}
              alt="Visualization"
              className="w-auto h-full lg:max-w-md"
            />
          </div>

          {/* Stats and Text Section */}
          <div className="lg:w-1/2 lg:text-left">
            {/* Heading */}
            <h2 className="text-3xl sm:text-4xl md:text-5xl font-semibold mb-8 sm:mb-10 md:mb-12 text-white">
              We’re only just getting started on our journey
            </h2>

            {/* Stats Container */}
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-8 text-left">
              {stats.map((stat, index) => (
                <motion.div
                  key={index}
                  whileHover={{
                    scale: 1.05,
                    transition: { duration: 0.4, ease: "easeOut" },
                  }}
                  className="text-white transform transition duration-300 ease-in-out"
                >
                  <p className="text-3xl sm:text-4xl md:text-5xl font-bold text-cyan-300 mb-1">
                    {stat.prefix && <span>{stat.prefix}</span>}
                    <CountUp
                      end={parseInt(stat.value.replace(/[^0-9]/g, ""))} // Convert value to a number
                      duration={3}
                      separator=","
                    />
                    {stat.suffix && <span>{stat.suffix}</span>}
                  </p>
                  <p className="text-lg sm:text-xl md:text-2xl text-white opacity-75">
                    {stat.label}
                  </p>
                </motion.div>
              ))}
            </div>
          </div>
        </motion.section>

        <Social1 />
        {/* Team Section with Hover, Rotation, and 3D Effects */}
        <motion.section
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: isVisible ? 1 : 0, y: isVisible ? 0 : 50 }}
          transition={{ duration: 0.6, delay: 0.6, ease: "easeOut" }}
          className="mb-12 sm:mb-16 md:mb-20 lg:mb-24 text-center flex flex-col justify-center items-center"
        >
          <h2 className="text-xl sm:text-2xl md:text-3xl lg:text-4xl font-bold mb-6 sm:mb-8 md:mb-10">
            Meet the Team
          </h2>
          <p className="max-w-md sm:max-w-lg md:max-w-2xl mx-auto text-sm sm:text-base md:text-lg lg:text-xl px-2 my-6">
            DeFa Core Team & Backers - Well Rounded Team With TradFi & DeFi
            Backgrounds!
          </p>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6 sm:gap-8 md:gap-10 lg:gap-12 justify-around w-[100%]">
            {teamMembers.map((member, index) => (
              <a href={member.link}>
                <motion.div className="flex flex-col items-center gap-2">
                  <motion.div
                    key={index}
                    whileHover={{
                      scale: 1.05,
                      rotate: 3,
                      boxShadow: "0 12px 24px rgba(0, 0, 0, 0.3)",
                      transition: { duration: 0.4, ease: "easeOut" },
                    }}
                    className="text-center w-[80%] bg-custom custom-radius shadow-lg hover:shadow-2xl transform transition duration-300 ease-in-out"
                  >
                    <img
                      src={member.image}
                      alt={member.name}
                      className="object-fit object-cover custom-width"
                    />
                  </motion.div>

                  <h3 className="font-bold text-sm sm:text-base md:text-lg">
                    {member.name}
                  </h3>
                  <p className="text-xs sm:text-sm md:text-base custom-text-color relative top-[-7px]">
                    {member.role}
                  </p>
                </motion.div>
              </a>
            ))}
          </div>
        </motion.section>
        <motion.div
          className="flex justify-center items-center mt-20"
          initial={{ opacity: 0, scale: 0.8 }}
          whileInView={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.8, ease: "easeOut", delay: 0.6 }}
          viewport={{ once: true }}
        >
          <img
            src={img}
            alt="Access-Image"
            className="h-12 w-auto object-contain"
          />
        </motion.div>
      </main>
      <Footer />
    </div>
  );
}

// import React from 'react'
// import "./Banners.css";
// import img from "../../assets/banner1.png";
// import img1 from "../../assets/banner2.png";
// import img2 from "../../assets/banner3.png";
// import img3 from "../../assets/banner4.png";

// const Banners = () => {
//   return (
//     <div className='max-w-5xl mx-auto banners-main mt-24 py-12'>
//       <img src={img} alt="" style={{width: "993px" , height: "341px"}} />
//       <img src={img1} alt="" className='mt-6' style={{width: "993px" , height: "341px"}} />
//       <img src={img2} alt="" className='mt-6' style={{width: "993px" , height: "341px"}} />
//       <img src={img3} alt="" className='mt-6' style={{width: "993px" , height: "341px"}} />
//     </div>
//   )
// }

// export default Banners

// import React from "react";
// import { motion } from "framer-motion";
// import img from "../../assets/banner1.png";
// import img1 from "../../assets/banner2.png";
// import img2 from "../../assets/banner3.png";
// import img3 from "../../assets/banner4.png";

// const Banners = () => {
//   const banners = [img, img1, img2, img3];

//   return (
//     <div className="max-w-6xl mx-auto mt-24 px-4 sm:px-6 lg:px-8">
//       {/* <h2 className="text-white text-2xl sm:text-3xl md:text-4xl lg:text-5xl font-bold text-center mb-12">
//         Why us
//       </h2> */}

//       {/* Banners Container */}
//       {banners.map((banner, index) => (
//         <motion.div
//           key={index}
//           initial={{ opacity: 0, y: 50 }}
//           whileInView={{ opacity: 1, y: 0 }}
//           transition={{ duration: 0.8, ease: "easeOut", delay: index * 0.2 }}
//           viewport={{ once: true }}
//           className="mb-8 relative overflow-hidden rounded-lg shadow-lg group"
//         >
//           {/* Banner Image */}
//           <motion.img
//             src={banner}
//             alt={`Banner ${index + 1}`}
//             className="w-full h-80 object-cover rounded-lg transition-transform duration-500 ease-in-out transform group-hover:scale-105"
//           />

//           {/* Hover Overlay Effect */}
//           <div className="absolute inset-0 bg-gradient-to-t from-black via-transparent to-transparent opacity-0 group-hover:opacity-70 transition-opacity duration-500 ease-in-out rounded-lg"></div>

//           {/* Text Overlay that appears only on hover */}
//           {/* <motion.div className="absolute inset-0 flex items-center justify-center text-white text-xl sm:text-2xl font-bold tracking-wide opacity-0 group-hover:opacity-100 transition-opacity duration-500 ease-in-out drop-shadow-lg">
//             Why us {index + 1}
//           </motion.div> */}
//         </motion.div>
//       ))}
//     </div>
//   );
// };

// export default Banners;

import React from "react";
import { motion } from "framer-motion";
import img from "../../assets/banner1.png";
import img1 from "../../assets/banner2.png";
import img2 from "../../assets/banner3.png";
import img3 from "../../assets/banner4.png";

const Banners = () => {
  const banners = [img, img1, img2, img3];

  return (
    <div className="max-w-6xl mx-auto mt-24 px-4 sm:px-6 lg:px-8">
      {banners.map((banner, index) => (
        <motion.div
          key={index}
          initial={{ opacity: 0, y: 50 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8, ease: "easeOut", delay: index * 0.2 }}
          viewport={{ once: true }}
          className="mb-8 relative overflow-hidden rounded-lg shadow-lg group"
        >
          {/* Banner Image */}
          <motion.img
            src={banner}
            alt={`Banner ${index + 1}`}
            className="w-full sm:h-80 h-auto object-cover rounded-lg transition-transform duration-500 ease-in-out transform group-hover:scale-105"
          />

          {/* Hover Overlay Effect */}
          <div className="absolute inset-0 bg-gradient-to-t from-black via-transparent to-transparent opacity-0 group-hover:opacity-70 transition-opacity duration-500 ease-in-out rounded-lg"></div>
        </motion.div>
      ))}
    </div>
  );
};

export default Banners;

// import React from 'react'
// import "./Social4.css";
// import img from "../../assets/s4-1.svg"
// import img1 from "../../assets/s4-2.webp"
// import img2 from "../../assets/s4-3.svg"
// import img3 from "../../assets/s4-4.svg"
// import img4 from "../../assets/s4-5.webp"
// import img5 from "../../assets/s4-6.svg"
// import img6 from "../../assets/s4-7.webp"
// import img7 from "../../assets/s4-8.svg"
// import img8 from "../../assets/s4-9.webp"
// import img9 from "../../assets/s4-10.svg"
// import img10 from "../../assets/s4-11.webp"
// import img11 from "../../assets/s4-12.webp"
// import img12 from "../../assets/s4-13.svg"
// import img13 from "../../assets/s4-14.webp"

// const Social4 = () => {
//   const topImages = [img, img1, img2, img3, img4, img5, img6, img7];
//   const bottomImages = [img8, img9, img10, img11, img12, img13];

//   return (
//     <div className='max-w-6xl mx-auto mt-12 sm:mt-16 md:mt-20 lg:mt-24 px-4 sm:px-6 lg:px-8 '>
//       <h2 className='social4-h text-center font-manrope text-xl sm:text-2xl md:text-3xl lg:text-4xl mb-8 sm:mb-12'>Accessing 53K + Businesses through our B2B Partners</h2>

//       <div className="grid grid-cols-2 sm:grid-cols-4 lg:grid-cols-8 gap-4 sm:gap-6 md:gap-8 justify-items-center mb-8">
//         {topImages.map((img, index) => (
//           <img
//             key={index}
//             src={img}
//             alt={`Partner ${index + 1}`}
//             className="w-20 h-20 sm:w-24 sm:h-24 md:w-28 md:h-28 object-contain"
//           />
//         ))}
//       </div>

//       <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-6 gap-4 sm:gap-6 md:gap-8 justify-items-center">
//         {bottomImages.map((img, index) => (
//           <img
//             key={index}
//             src={img}
//             alt={`Partner ${index + 9}`}
//             className="w-20 h-20 sm:w-24 sm:h-24 md:w-28 md:h-28 object-contain"
//           />
//         ))}
//       </div>
//     </div>
//   )
// }

// export default Social4

import React from "react";
import { motion } from "framer-motion"; // Importing framer-motion
import "./Social4.css";
import img from "../../assets/s4-1.svg";
import img1 from "../../assets/s4-2.webp";
import img2 from "../../assets/s4-3.svg";
import img3 from "../../assets/s4-4.svg";
import img4 from "../../assets/s4-5.webp";
import img5 from "../../assets/s4-6.svg";
import img6 from "../../assets/s4-7.webp";
import img7 from "../../assets/s4-8.svg";
import img8 from "../../assets/s4-9.webp";
import img9 from "../../assets/s4-10.svg";
import img10 from "../../assets/s4-11.webp";
import img11 from "../../assets/s4-12.webp";
import img12 from "../../assets/s4-13.svg";
import img13 from "../../assets/s4-14.webp";
import img14 from "../../assets/telec.webp";

const Social4 = () => {
  const topImages = [img, img1, img2, img3, img4, img5, img6, img7,img8, img9, img10, img11, img12, img13,img14];
  // const bottomImages = [];

  // Framer Motion animation variants for staggered animations
  const container = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.15, // Controls the delay between animations for each child
        delayChildren: 0.1,
      },
    },
  };

  const item = {
    hidden: { opacity: 0, y: 50 },
    visible: {
      opacity: 1,
      y: 0,
      transition: { duration: 0.6, ease: "easeOut" },
    },
  };

  return (
    <div className="max-w-6xl mx-auto mt-12 sm:mt-16 md:mt-20 lg:mt-24 px-4 sm:px-6 lg:px-8">
      <h2 className="social4-h text-center font-manrope text-xl sm:text-2xl md:text-3xl lg:text-4xl mb-8 sm:mb-12">
        Accessing 53K + Businesses through our B2B Partners
      </h2>

      {/* Top Images with Framer Motion and hover effects */}
      <motion.div
        className="flex flex-wrap justify-center items-center gap-4 sm:gap-6 md:gap-8 mt-8 mb-8 mx-auto"
        variants={container}
        initial="hidden"
        animate="visible"
      >
        {topImages.map((img, index) => (
          <motion.img
            key={index}
            src={img}
            alt={`Partner ${index + 1}`}
            className="w-20 h-20 sm:w-24 sm:h-24 md:w-28 md:h-28 object-contain"
            variants={item}
            whileHover={{ scale: 1.1 }} // Hover effect for scaling the image
            transition={{ type: "spring", stiffness: 300 }}
          />
        ))}
      </motion.div>

      {/* Bottom Images with Framer Motion and hover effects */}
      {/* <motion.div
        className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-6 gap-4 sm:gap-6 md:gap-8 flex justify-items-center"
        variants={container}
        initial="hidden"
        animate="visible"
      >
        {bottomImages.map((img, index) => (
          <motion.img
            key={index}
            src={img}
            alt={`Partner ${index + 9}`}
            className="w-20 h-20 sm:w-24 sm:h-24 md:w-28 md:h-28 object-contain"
            variants={item}
            whileHover={{
              scale: 1.1,
              rotate: 2, // Slight rotation on hover
            }}
            transition={{ type: "spring", stiffness: 250, damping: 20 }} // Smoother spring effect
          />
        ))}
      </motion.div> */}
    </div>
  );
};

export default Social4;

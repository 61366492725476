import { motion } from "framer-motion";
import CountUp from "react-countup";
import "./Counter.css";

export default function Counter() {
  return (
    <div className="counter max-w-6xl mx-auto text-white px-4 sm:px-12 py-2 mt-4 bg-blue-600 shadow-lg rounded-2xl">
      <div className="grid grid-cols-1 sm:grid-cols-3 gap-6 text-center">
        {/* Counter Item 1 */}
        <motion.div
          initial={{ opacity: 0, scale: 0.8 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.8, ease: "easeOut", delay: 0.2 }}
          className="p-4 flex flex-col items-center"
        >
          <p className="counter-text font-manrope text-center" style={{ color: "#57FFFF" }}>
            $<CountUp end={275633890} duration={4} separator="," />+
          </p>
          <p className="counter-p font-manrope text-center">Assets Tokenized</p>
        </motion.div>

        {/* Counter Item 2 */}
        <motion.div
          initial={{ opacity: 0, scale: 0.8 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.8, ease: "easeOut", delay: 0.4 }}
          className="p-4 flex flex-col items-center"
        >
          <p className="counter-text font-manrope text-center" style={{ color: "#57FFFF" }}>
            $<CountUp end={50845873} duration={4} separator="," />+
          </p>
          <p className="counter-p font-manrope text-center">Invoice Financed</p>
        </motion.div>

        {/* Counter Item 3 */}
        <motion.div
          initial={{ opacity: 0, scale: 0.8 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.8, ease: "easeOut", delay: 0.6 }}
          className="p-4 flex flex-col items-center"
        >
          <p className="counter-text font-manrope text-center" style={{ color: "#57FFFF" }}>
            $<CountUp end={0} duration={4} />
          </p>
          <p className="counter-p font-manrope text-center">Bad Debts</p>
        </motion.div>
      </div>
    </div>
  );
}

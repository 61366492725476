import React from "react";
import { motion } from "framer-motion";
import img from "../../assets/world.png";
import "./World.css";

const World = () => {
  return (
    <div className="max-w-6xl mx-auto px-4 pt-36 sm:py-24">
      {/* Heading Animation */}
      <motion.div
        initial={{ opacity: 0, y: -50 }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8, ease: "easeOut" }}
        viewport={{ once: true }}
      >
        <h1 className=" world-h text-xl sm:text-2xl md:text-3xl lg:text-3xl text-center font-manrope">
          Real Economic Activity Converted
        </h1>
        <h2 className="world-h text-xl sm:text-2xl md:text-3xl lg:text-3xl text-center font-manrope">
          Into Stable and Secure On-Chain Yields
        </h2>
      </motion.div>

      {/* Image Animation */}
      <motion.div
        className="flex justify-center items-center py-14"
        initial={{ opacity: 0, scale: 0.8 }}
        whileInView={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.8, ease: "easeOut", delay: 0.2 }}
        viewport={{ once: true }}
      >
        <img
          src={img}
          alt="World Map"
          className="w-4/5 sm:w-3/4 md:w-2/3 lg:w-1/2 h-auto"
        />
      </motion.div>

      {/* Description Animation */}
      <motion.p
        className="world-p text-lg sm:text-xl md:text-2xl lg:text-3xl text-white font-manrope text-center"
        initial={{ opacity: 0, y: 50 }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8, ease: "easeOut", delay: 0.4 }}
        viewport={{ once: true }}
      >
        Verified & Risk Scored invoices are converted into financial instruments
        that enable short-term loan propositions with quick settlements for
        Quarterly Percentage Yields (QPY).
      </motion.p>
    </div>
  );
};

export default World;

import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import Marquee from "react-fast-marquee";
import img from "../../assets/s3-1.png";
import img1 from "../../assets/s3-2.png";
import img2 from "../../assets/Cointelegraph.png";
import img3 from "../../assets/Khaleej.png";
import img4 from "../../assets/s3-5.png";
import img5 from "../../assets/s3-6.png";
import img6 from "../../assets/CNBN.png";
import img7 from "../../assets/Bloomberg.png";
import img8 from "../../assets/Entrepreneur.png";
import "./Social4.css";

const logos = [
  { src: img, width: "175px", height: "64px" },
  { src: img1, width: "175px", height: "64px" },
  { src: img2, width: "139px", height: "64px" },
  { src: img3, width: "119px", height: "64px" },
  { src: img4, width: "129px", height: "64px" },
  { src: img5, width: "120px", height: "64px" },
  { src: img6, width: "129px", height: "64px" },
  { src: img7, width: "113px", height: "64px" },
  { src: img8, width: "115px", height: "64px" },
];

const Social3 = () => {
  const [logoSize, setLogoSize] = useState({ width: "175px", height: "64px" });

  useEffect(() => {
    const updateLogoSize = () => {
      if (window.innerWidth <= 640) {
        setLogoSize({ width: "80px", height: "40px" });
      } else if (window.innerWidth <= 768) {
        setLogoSize({ width: "100px", height: "50px" });
      } else {
        setLogoSize({ width: "175px", height: "64px" });
      }
    };

    // Set the initial logo size
    updateLogoSize();

    // Listen for window resize events
    window.addEventListener("resize", updateLogoSize);

    // Cleanup event listener on component unmount
    return () => window.removeEventListener("resize", updateLogoSize);
  }, []);

  return (
    <div className="mt-44 mb-24">
      <div className="max-w-6xl mx-auto px-4">
        {/* Heading */}
        <motion.h1
          className="social4-h text-white text-center font-bold font-manrope text-xl sm:text-2xl md:text-3xl lg:text-4xl mb-8 sm:mb-12"
          initial={{ opacity: 0, y: -50 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8, ease: "easeOut" }}
        >
          Featured By
        </motion.h1>

        {/* Marquee for logos */}
        <Marquee
          gradient={false}
          speed={50}
          pauseOnHover={true}
          className="py-6"
        >
          {logos.map((logo, index) => (
            <motion.div
              key={index}
              className="mx-8 flex-shrink-0"
              whileHover={{ scale: 1.1 }}
              transition={{ duration: 0.4, ease: "easeOut" }}
            >
              <img
                src={logo.src}
                alt={`Logo ${index + 1}`}
                className="object-contain"
                style={{
                  width: logoSize.width,
                  height: logoSize.height,
                }}
              />
            </motion.div>
          ))}
        </Marquee>
      </div>
    </div>
  );
};

export default Social3;

import Access from "../../components/Access/Access";
import Banners from "../../components/Banners/Banners";
import Counter from "../../components/Counter/Counter";
import Decentra from "../../components/Decentra/Decentra";
import Footer from "../../components/Footer/Footer";
import Hero from "../../components/Hero/Hero";
import Navbar from "../../components/Navbar/Navbar";
import Social1 from "../../components/Social1/Social1";
import Social2 from "../../components/Social2/Social2";
import Social3 from "../../components/Social3/Social3";
import Social4 from "../../components/Social4/Social4";
import World from "../../components/World/World";
import './Home.css';

export default function Home() {
  return (
    <div className="min-h-screen home-main">
     <Navbar/>
     <Hero/>
     <Counter/>
     <Social1/>
     <Decentra/>
     <World/>
     <Banners/>
     <Social2/>
     <Social4/>
     <Social3/>
     <Access/>
     <Footer/>
    </div>
  )
}
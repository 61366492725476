import React from "react";
import Marquee from "react-fast-marquee";
import "./Social1.css";
import { motion } from "framer-motion";
import img from "../../assets/Iota.png";
import img1 from "../../assets/Brinc.png";
import img3 from "../../assets/MBRIF.png";
import img4 from "../../assets/DFINITY.png";
import img5 from "../../assets/TDeFi.png";
import img6 from "../../assets/CryptoOasis.png";
import img8 from "../../assets/black.webp";
import img9 from "../../assets/sma.webp";
import img10 from "../../assets/DIFC.png";

const backers = [
  { image: img, name: "IOTA" },
  { image: img1, name: "Brinc" },
  { image: img3, name: "Muhammad Bin Rashid Foundation" },
  { image: img4, name: "Dfinity" },
  { image: img5, name: "TDefi" },
  { image: img6, name: "Crypto Oasis" },
  { image: img8, name: "Block Alpha Capital " },
  { image: img9, name: "SMA Family Office" },
  { image: img10, name: "DIFC" },
];

export default function OurBackers() {
  return (
    <div className="mt-24 px-4 sm:px-6 md:px-8 lg:px-32 overflow-hidden"> {/* Added extra padding on larger screens */}
      {/* Backers Title */}
      <div className="text-center">
        <h2 className="social1-h text-xl sm:text-2xl md:text-3xl lg:text-3xl font-jakarta">
          OUR BACKERS
        </h2>
      </div>

      {/* Marquee for Backers with Hover Effects */}
      <Marquee
        gradient={false}
        speed={50} /* Adjust speed for smoothness */
        pauseOnHover={true}
        className="flex gap-2 overflow-hidden"
      >
        {backers.map((backer, index) => (
          <motion.div
            key={index}
            className="mx-1 p-2"
            whileHover={{
              scale: 1.1,
              transition: { duration: 0.4, ease: "easeOut" },
            }}
            style={{ transformOrigin: "center" }} // Smooth scaling
          >
            <img
              src={backer.image}
              alt={backer.name}
              className="hover:scale-110 transition-transform duration-300 w-40 h-40  object-contain"
              style={{              
                margin: "0 auto", // Center the logos horizontally
              }}
            />
          </motion.div>
        ))}
      </Marquee>
    </div>
  );
}
